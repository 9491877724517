import React, { Component } from 'react';
import {
  Form, Input, Button, Modal, message
} from 'antd';
import {MailOutlined} from '@ant-design/icons';


import { withFirebase } from '../Firebase';
import styles from '../../index.css';


const INITIAL_STATE = {
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, visible: this.props.visible };
  }


  handleCancel = () => {
    this.props.form.resetFields();
    console.log("reset fields");
    this.props.handleCancel();
  };


  onFinish = values => {

    const { email } = values;


    console.log('Received values of form: ', values);

    this.props.firebase
      .doPasswordReset(email)
      .then(() => this.props.handleCancel())
      .catch(error => {
        this.setState({ error });
        console.log('pwreset error', error);
        message.error(error.message, 2);
      });


  };



  render() {

    return (

      <Modal
        visible={this.props.visible}
        title="Password Reset"
        onCancel={this.handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >
        <Form className={styles["login-form"]} onFinish={this.onFinish} name="password_forget" form={this.props.form}>
          {/* style={{maxWidth: '300px',margin: 'auto'}} */}

          <Form.Item
            name="email"
            rules={[{ type: 'email', message: 'Not a valid email address' },
            { required: true, message: ' ' }]}
            validateTrigger={['onBlur']}
          >
            <Input allowClear name="email" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="Email address" onChange={this.onChange} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Reset My Password
            </Button>

          </Form.Item>
        </Form>
      </Modal>

    );
  }
}



// const WrappedPasswordForgetFormBase = Form.create({ name: 'password_forget' })(PasswordForgetFormBase);

const WrappedPasswordForgetFormBase = (props) => {
  const [form] = Form.useForm();
  return <PasswordForgetFormBase form={form} {...props} />
}



const PasswordForgetForm = withFirebase(WrappedPasswordForgetFormBase);

export default PasswordForgetForm;

