import React, { useRef, useState, Fragment } from 'react';
import BraftEditor from 'braft-editor';
// import { EditorState, convertToRaw } from 'draft-js';

import { convertRawToHTML, convertEditorStateToRaw, convertRawToEditorState, convertEditorStateToHTML } from 'braft-convert';
import 'braft-editor/dist/index.css';
import { Icon, message } from "antd";
import moment, { max } from 'moment';
import IdleTimer from 'react-idle-timer';

import { PiIcon } from '../Icons';

import MathModal from './math-modal';
import { useWrite2Db, useInterval } from '../Hooks';

const initialContent = '';

// EditorState.createEmpty()



// function IntervalCounter(props) {
//     let [count, setCount] = useState(0);
//     const [interval, setInterval] = useState(props.interval);
//     const [isRunning, setIsRunning] = useState(props.isRunning);

//     useInterval(() => {
//       props.onInterval();
//       setCount(count + 1);
//     }, isRunning ? interval : null);

//     return <h1>{count}</h1>;
//   }



// the above doen's work because props only set the initial value of interval and isRunning
// hence updating the props didn't affect the counter
// just lift the states up like below, and it works



function IntervalCounter(props) {
    let [count, setCount] = useState(0);

    useInterval(() => {
        props.onInterval();
        setCount(count + 1);
    }, props.isRunning ? props.interval : null);

    return <h1>{count}</h1>;
}


export default function MainEditor(props) {

    const idletimer = useRef(null);

    const [isIntervalCounterRunning, SetIsIntervalCounterRunning] = useState(true);

    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(initialContent, {
        editorId: 'main-editor'
    }));

    const [textlen, setTextlen] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const { loading, error, setData } = useWrite2Db(props.firebase, 'userposts', props.authUser.uid); // adding child argument: `/regular`

    const controls = [
        'undo', 'redo', 'separator',
        'font-size', 'font-family', 'line-height',
        'separator',
        'text-color', 'bold', 'italic', 'underline',
        {
            key: 'strike-through',
            title: 'Strike-through',
            text: <Icon type="strikethrough" />
        },
        'separator',
        {
            key: 'superscript',
            title: 'Superscript',
        },
        {
            key: 'subscript',
            title: 'Subscript',
        },

        'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'separator', 'hr', 'separator',
        'media', 'separator',
        'clear',
    ];

    // async componentDidMount () {
    //   // 假设此处从服务端获取html格式的编辑器内容
    //   const htmlContent = await fetchEditorContent();
    //   // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorState数据
    //   this.setState({
    //     editorState: BraftEditor.createEditorState(htmlContent);
    //   })
    // }

    // submitContent = async () => {
    //   // 在编辑器获得焦点时按下ctrl+s会执行此方法
    //   // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
    //   const htmlContent = this.state.editorState.toHTML()
    //   const result = await saveEditorContent(htmlContent)
    // }

    const saveEditorContent = (content) => {
        console.log(content);
    }


    const doSubmit = (newlen, contentObject, contentString, texts) => {
        const submitTime = Math.round(moment().format('X'));
        const submitObj = {
            submitTime,
            contentObject,
            contentString,
            texts
        }
        setTextlen(newlen);
        setData(submitObj);
    }

    const submitContent = async () => {
        // 在编辑器获得焦点时按下ctrl+s会执行此方法
        // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
        const contentObject = convertEditorStateToRaw(editorState);
        const contentString = editorState.toRAW();
        const texts = contentObject.blocks.map(b => b.text).join(' ');
        const newlen = texts.length;
        //if there is an image, open up and you'll see multiple undefined properties
        console.log("evaluating whether to submit", contentObject);
        doSubmit(newlen, contentObject, contentString, texts);

    //   console.log(contentObj.entityMap.0.data.link);
    //   const htmlContent = editorState.toHTML();
    //   const result = await saveEditorContent(htmlContent);
    //   console.log(result);
}


const autoSubmitContent = async () => {
    // this is the same as submitContent, except I do a crude change check
    // on the length of content
    // and if little change, don't submit
    const contentObject = convertEditorStateToRaw(editorState);
    const contentString = editorState.toRAW();
    const texts = contentObject.blocks.map(b => b.text).join(' ');
    const newlen = texts.length;
    //if there is an image, open up and you'll see multiple undefined properties
    console.log("evaluating whether to submit", contentObject);
    if (Math.abs(newlen - textlen) > 50) {
        doSubmit(newlen, contentObject, contentString, texts);
    } else {
        message.warn("not enough change to warrant submission")
    }
}

const handleEditorChange = (est) => {
    setEditorState(est);
    console.log(editorState.toRAW());
}

const handleModalClick = (e) => {
    setModalVisible(true);
    // this.setState({editorState: ContentUtils.insertAtomicBlock(this.state.editorState, 'TEX', true, {newtex: 'mimimaw'})});
}

const handleModalCancel = () => {
    setModalVisible(false);
}



// handleMath = (e) => {
//     console.log(e);
//     const temp = this.state.editorState.createEntity({
//         type: 'math',
//         mutability: 'immutable',
//         data: { 'teX': 'x^2=15' }
//     }
//     );
//     console.log(temp);
// }



const extendControls = [
    'separator',
    {
        key: 'antd-uploader',
        type: 'component',
        component: (
            <PiIcon style={{ fontSize: '22px', align: "center" }} onClick={handleModalClick} />
        )
    }
]

const onIdle = () => {
    // console.log(idletimer.current && idletimer.current.isIdle());
    // idletimer.current && idletimer.current.reset();
    // console.log(idletimer);
    submitContent();
    console.log("is idle now");
    SetIsIntervalCounterRunning(false);
}

// const onAction = () => {
//     console.log(idletimer.current && idletimer.current.isIdle());
//     idletimer.current && idletimer.current.resume();
//     console.log(idletimer);
// }

const onActive = () => {
    // console.log(idletimer.current && idletimer.current.isIdle());
    // idletimer.current && idletimer.current.resume();
    SetIsIntervalCounterRunning(true);
}


return (
    <Fragment>
        <IntervalCounter
            onInterval={autoSubmitContent}
            interval={1000 * 60 * 1}
            isRunning={isIntervalCounterRunning}
        />
        <IdleTimer
            ref={idletimer}
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            //   onAction={onAction}
            debounce={250}
            timeout={1000 * 60 * 2} />
        <BraftEditor
            id='main-editor'
            language='en'
            value={editorState}
            onChange={handleEditorChange}
            onSave={submitContent}
            controls={controls}
            // onClick={this.handelClick}
            // onMathClick={this.handleMathClick}
            extendControls={extendControls}
        // draftProps={{ plugins: plugins, blockRendererFn: myBlockRenderer }}
        // draftProps={{ blockRenderMap: extendedBlockRenderMap }}
        />

        <MathModal
            visible={modalVisible}
            handleModalCancel={handleModalCancel}
        />
    </Fragment>
)


}
