import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';
import Recaptcha from 'react-recaptcha';
import {
    Form, Input, Tooltip, Checkbox, Button, Modal, message, Alert, Row
} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
// import regForm from './form';
import {createUser, verifyCaptcha} from '../Functions';

// import styles from '../../index.css';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try login with that account instead. If you think the
  email is already used from one of the social logins, try
  log in with one of them. Afterwards, link your accounts
  on your personal account page.
`;

const INITIAL_STATE = {
    error: null,
    confirmDirty: false,
    loading: false,
    alert: false,
    captcha: false,
    // captchatoken: null,
};

// define a variable to store the recaptcha instance
let recaptchaInstance;



const sitekey = '6Lfk-eQUAAAAAHwOgvAOzSKfTeiBjSwEWKwndIo8';



// const expiredCallback = () => {
//   console.log(`Recaptcha expired`);
// };

// specifying your onload callback function
// has to be before recaptcha loads. So add it to window
window.onloadCallback = () => {
    console.log('loaded');
};

class RegistrationForm extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    // callback = () => {
    //     console.log('loaded');
    // };

    verifyCallback = (response) => {

        if (response) {
            verifyCaptcha(response)
            .then(res => {
                if (res.status === 200) {
                this.setState({captcha: true});
                }
                console.log(res);
            })

        }
    };

    resetRecaptcha = () => {
        recaptchaInstance.reset();
      };
      

    onClose = () => {
        this.setState({ alert: false });
    };


    handleCancel = () => {
        this.setState({  ...INITIAL_STATE });
        this.props.form.resetFields();
        console.log("reset fields");
        this.resetRecaptcha();
        this.props.handleCancel();
    };


    // onSubmit = async (e) => {
        onFinish = async (values) => {
        // const form = this.props.form;
        const {email, password, username} = values;
        // const email = form.getFieldValue('email');
        // const password = form.getFieldValue('password');
        // const username = form.getFieldValue('nickname');
        // const captchatoken = this.state.captchatoken;

        const params = this.props.location.search;
        const refer = (params && queryString.parse(params).refer) || '';
        const captcha = this.state.captcha;

        // e.preventDefault();

        if (!captcha) {
            message.error("Please check the reCaptcha");
            return;
        }

        await this.setState({ loading: true });

        console.log('refer:', refer);

        // this.props.form.validateFields((err, values) => {
        //     if (!err) {

                this.props.firebase
                    .doCreateUserWithEmailAndPassword(email, password)
                    .then(authUser => createUser(authUser, refer, username, email))
                    .then(() => {
                        this.props.firebase.doSendEmailVerification();
                        message.success("A verification email has been sent to you", 1.5);
                        this.props.history.push(ROUTES.QUIZ);
                        this.handleCancel();
                    })
                    .catch(error => {
                        console.log('update error', error);
                        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                            this.setState({ alert: true });
                            // error.message = ERROR_MSG_ACCOUNT_EXISTS;
                            console.log(error.message);
                        } else { message.error(error.message,2); }
                        // this.setState({ error });
                        this.setState({ ...INITIAL_STATE});
                    });
        //     } else {
        //         this.setState({ loading: false });
        //         message.error('Please check your entries for error', 1);
        //         console.log("here's the validation error:", err);
        //     }
        // });


    }




    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    // compareToFirstPassword = (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (value && value !== form.getFieldValue('password')) {
    //         callback('The passwords don\'t match');
    //         // this.setState({submitDisabled: true});
    //     } else {
    //         // this.setState({submitDisabled: false});
    //         callback();
    //     }
    // }

    compareToFirstPassword = async (rule, value) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            // callback('The passwords don\'t match');
            throw new Error('The passwords don\'t match');
            // this.setState({submitDisabled: true});
        } 
    }


    // validateToNextPassword = (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (value && this.state.confirmDirty) {
    //         form.validateFields(['confirm'], { force: true });
    //     }
    //     callback();
    // }


    validateToNextPassword = async (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        // callback();
    }



    render() {
        // const { getFieldDecorator } = this.props.form;


        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 24 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 24 },
            },
          };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 16,
                    offset: 8,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const PossibleAlert = () => this.state.alert ?
            <Alert
                message="Duplicate Account"
                description={ERROR_MSG_ACCOUNT_EXISTS}
                type="error"
                closable={true}
                onClose={this.onClose}
                banner={true}
            /> : <div></div>

        return (


            <Modal
                visible={this.props.visible}
                title="Create a new account"
                onCancel={this.handleCancel}  //remove this so that pressing esc doesn't bring in the close of the modal
                footer={null}
                closable={true}
                maskClosable={false}
            >
                <PossibleAlert />
                <Form onFinish={this.onFinish} name="register" form={this.props.form} {...formItemLayout}>  
                {/* className={styles["login-form"]}  */}
                    <Form.Item
                        // {...formItemLayout}
                        name="email"
                        label="Email"
                        labelAlign="left"
                        extra="A verification email will be sent to this address"
                        rules={[{ type: 'email', message: 'Not a valid email address' },
                                { required: true, message: ' ' }]}
                        validateTrigger= {['onBlur']}
                    >
                        <Input allowClear name="email" onChange={this.onChange}/>
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        name='password'
                        labelAlign="left"
                        label={(
                            <span>
                                Password&nbsp;
                <Tooltip title="Use letters, numbers and special characters">
                                    <QuestionCircleOutlined/>
                                </Tooltip>
                            </span>
                        )}
                        validateTrigger={['onBlur']}
                            rules={ [{
                                required: true, message: 'Password must be at least 6 characters', min: 6,
                            }, {
                                validator: this.validateToNextPassword,
                            }]}
                    >
                        <Input.Password name="passwordOne" type="password" onChange={this.onChange} />
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        name='confirm'
                        label="Confirm Password"
                        labelAlign="left"
                        validateTrigger={['onBlur']}
                        rules={[{
                            required: true, message: 'Please input your password again',
                        }, {
                            validator: this.compareToFirstPassword,
                        }]}
                    >
                        <Input.Password name="passwordTwo" type="password" onBlur={this.handleConfirmBlur} onChange={this.onChange} />
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        name='username'
                        labelAlign="left"
                        label={(
                            <span>
                                Nickname&nbsp;
                <Tooltip title="What do you want others to call you?">
                                    <QuestionCircleOutlined/>
                                </Tooltip>
                            </span>
                        )}
                        validateTrigger={['onBlur']}
                        rules={[{ required: true, message: ' ', whitespace: true }]}
                    >
                        <Input allowClear onChange={this.onChange}/>
                    </Form.Item>

                    <Form.Item 
                        // {...formItemLayout}
                        // label="Captcha"
                    >
                    <Recaptcha 
                        ref={e => recaptchaInstance = e}
                        sitekey={sitekey}
                        // size="compact"
                        render="explicit"
                        verifyCallback={this.verifyCallback}
                        onloadCallback={window.onloadCallback}
                        theme = "light"
                        // expiredCallback={expiredCallback}
                        />

                    </Form.Item>

                    <Form.Item
                        {...tailFormItemLayout}
                        name='agreement'
                        valuePropName='checked'
                        validateTrigger={['onBlur']}
                        rules={[{
                            required: true,
                            transform: value => (value || undefined),
                            type: 'boolean',
                            message: 'Agree to proceed'
                        }]}
                    >
                            <Checkbox onChange={this.onChange}>I agree to the <a href=" #">Terms</a></Checkbox>
                    </Form.Item>



                    <Form.Item
                    >
                        <Row type="flex" justify="center" align="center">
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>Sign Up</Button>
                        </Row>
                    </Form.Item>

                </Form>
            </Modal>

        );
    }
}

// const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

// export default WrappedRegistrationForm;



const WrappedRegistrationForm  = (props) => {
    const [form] = Form.useForm();
    return <RegistrationForm form={form} {...props}/>
  }




const SignUpForm = compose(
  withRouter,
  withFirebase,
)(WrappedRegistrationForm);


export default SignUpForm;

// export { SignUpForm, SocialDisclaimer };