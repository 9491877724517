import React from 'react';
import Icon from '@ant-design/icons';


const TargetSvg = () => (
    <svg viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor">
        <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M12,6A6,6 0 0,0 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12A6,6 0 0,0 12,6M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10Z" />
    </svg>
);

export const TargetIcon = props => (
    <Icon component={TargetSvg} {...props} />
);


const TargetArrowSvg = () => (
    <svg viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor">
        <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,10.84 21.79,9.69 21.39,8.61L19.79,10.21C19.93,10.8 20,11.4 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.6,4 13.2,4.07 13.79,4.21L15.4,2.6C14.31,2.21 13.16,2 12,2M19,2L15,6V7.5L12.45,10.05C12.3,10 12.15,10 12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.85 14,11.7 13.95,11.55L16.5,9H18L22,5H19V2M12,6A6,6 0 0,0 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12H16A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8V6Z" />
    </svg>
);

export const TargetArrowIcon = props => (
    <Icon component={TargetArrowSvg} {...props} />
);


const Punchcard = () => (
    <svg viewBox="0 0 64 64" width="1em" height="1em" fill="currentColor">
        <path d="M54,15l-42.414,0L5,21.586V44c0,2.757,2.243,5,5,5h44c2.757,0,5-2.243,5-5V20C59,17.244,56.757,15,54,15z M44,22h2v2h-2V22   z M24,22h2v2h-2V22z M14,22h2v2h-2V22z M14,28h2v2h-2V28z M16,42h-2v-2h2V42z M18,36h-2v-2h2V36z M18,28h2v2h-2V28z M22,42h-2v-2h2   V42z M22,36h-2v-2h2V36z M22,28h2v2h-2V28z M26,42h-2v-2h2V42z M28,30h-2v-2h2V30z M30,24h-2v-2h2V24z M34,36h-2v-2h2V36z M34,30   h-2v-2h2V30z M38,36h-2v-2h2V36z M38,30h-2v-2h2V30z M42,42h-2v-2h2V42z M42,24h-2v-2h2V24z M46,42h-2v-2h2V42z M48,36h-2v-2h2V36z    M52,30h-2v-2h2V30z" />
    </svg>
);

export const PunchcardIcon = props => (
    <Icon component={Punchcard} {...props} />
);


const Shelf = () => (
    <svg viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor">
        <path d="M9 3V18H12V3H9M12 5L16 18L19 17L15 4L12 5M5 5V18H8V5H5M3 19V21H21V19H3Z" />
    </svg>
);

export const ShelfIcon = props => (
    <Icon component={Shelf} {...props} />
);


const Pi = () => (
    <svg viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor">
    <path d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M6,7H17V9H15V14A1,
    1 0 0,0 16,15A1,1 0 0,0 17,14H19A3,3 0 0,1 16,17A3,3 0 0,1 13,14V9H10V17H8V9H6" />
    </svg>
);

// const Pi = () => (
//     <svg viewBox="0 0 93.558 93.558" width="1em" height="1em" fill="currentColor">
//     <path d="M72.766,74.275c-13.366-10.451-3.933-53.438-3.933-53.438s14.631,0,16.336,0s8.389-1.072,8.389-9.076
// 			c0-9.846-8.168-9.241-8.168-9.241s-44.774,0-60.147,0c-9.873,0-18.784,11.304-23.899,19.389c-2.723,4.318-1.183,5.528,3.493,3.493
// 			c16.392-7.178,21.012-4.593,21.012-4.593c1.155,5.528,1.54,30.473-2.667,40.318c-4.263,9.269-12.128,21.012-0.578,27.915
// 			c9.021,5.115,18.481-5.391,17.546-23.268c-0.44-9.461-1.183-44.938-1.183-44.938h15.072c-0.469,5.528-3.025,33.745-0.357,50.467
// 			c2.777,27.915,33.112,22.497,38.777,6.685c1.402-4.373,1.073-7.729,0.027-10.26c-1.926-4.702-5.144-4.949-4.785-2.75
// 			c0.221,1.402,0.083,2.777-0.138,4.016C86.902,72.817,79.092,78.811,72.766,74.275z"/>
//     </svg>
// );


// const Pi = () => (
//     <svg viewBox="0 0 500 500" width="1em" height="1em" fill="currentColor">
//     <path d="M458.667,0h-448C4.771,0,0,4.771,0,10.667v64c0,5.896,4.771,10.667,10.667,10.667h74.635
// 			C84.76,183.396,76.865,412.177,45,452c-2.563,3.208-3.052,7.594-1.281,11.292c1.781,3.688,5.51,6.042,9.615,6.042h64
// 			c3.24,0,6.302-1.469,8.333-4c40.729-50.927,44.625-316.167,44.969-380h128.031v309.333c0,41.167,33.5,74.667,74.667,74.667h64
// 			c3.427,0,6.646-1.646,8.656-4.427c2-2.781,2.552-6.365,1.458-9.615l-21.333-64C424.667,386.938,420.594,384,416,384l-21.302,0.01
// 			h-0.01c-5.875,0-10.667-4.781-10.667-10.656L384,85.333h74.667c5.896,0,10.667-4.771,10.667-10.667v-64
// 			C469.333,4.771,464.563,0,458.667,0z"/>
//     </svg>
// );



export const PiIcon = props => (
    <Icon component={Pi} {...props} />
);