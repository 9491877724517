export const LANDING = '/';
// export const HOME = '/home';
export const PROFILE = '/profile';
// export const ADMIN = '/admin';
export const QUIZ = '/quiz';
export const EXTEND = '/extend';
// export const RECOMMEND = '/recommend';
// export const DESCRIPTION = '/description';
// export const LIKES = '/likes';
export const SETTINGS = '/settings';
export const GOALS = '/goals';
export const PUNCHCARD = '/punchcard';
export const ROUTINES = '/routines';
// export const LANDING = '/';
// export const HOME = '/home';
// export const PROFILE = '/profile';
// export const ADMIN = '/admin';
// export const QUIZ = '/quiz';
// export const EXTEND = '/extend';
// export const RECOMMEND = '/recommend';
// export const DESCRIPTION = '/description';
// export const LIKES = '/likes';
// export const SETTINGS = '/settings';
export const NOTEBOOKS = '/notebooks';
export const EDIT = '/edit';
// export const ROUTINES = '/routines';