import React, { useRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Link, NavLink } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button, Table, Typography, Input, Row} from "antd";
import {SearchOutlined} from '@ant-design/icons';

import fileattr from '../../constants/fileattr';
// import mapAgeCleaner from 'map-age-cleaner';

// import {createUser} from '../Functions';

const { Title } = Typography;

const { Search } = Input;

// const testuser = {
//     user: {uid: 'erareare'}
// };

// createUser(testuser, 'erereq', 'epet', 'atee@gagdfa.com');


function Landing(props) {

    const [searchText, setSearchText] = useState('');

    const inputEl = useRef(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    //   ref={node => {
                    //     this.searchInput = node;
                    //   }}
                    ref={inputEl}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => this.searchInput.select());
                setTimeout(() => inputEl.current.select());
            }
        },
        render: text => (
            <a href={`pdf/newpdf/${text}.pdf`}
            ><Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            </a>
        ),
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };




    const columns = [
        {
            title: 'Collection',
            dataIndex: 'filename',
            // render: text => <a href={`pdf/newpdf/${text}.pdf`}>{text}</a>,
            width: 400,
            sorter: (a, b) => { return a.filename.localeCompare(b.filename) },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('filename'),
        },
        {
            title: 'Date Created',
            className: 'column-date',
            dataIndex: 'type',
            width: 150,
            filters: [{ text: 'MLPA', value: 'MLPA' },
            { text: 'prospectus', value: 'prospectus' }],
            onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
            title: 'Date Modified',
            className: 'column-date',
            dataIndex: 'NOTEBOOK',
            render: (value, row) => value === "0" ? 'None'
                : (row.NOTEBOOKtype === '' ? <a href={`pdf/htmls/${row.filename}_highlighted_NOTEBOOK.html`}>Unknown</a> : row.NOTEBOOKtype.split(", ").map(
                    (v, i) => <div key={i}><a href={`pdf/htmls/${row.filename}_highlighted_${v}.html`}>
                        {v.replace(' NOTEBOOK', '')}</a></div>)),
            width: 150,
            filters: [{ text: 'Lockable', value: 'Lockable NOTEBOOK' },
            { text: 'Leather-bound', value: 'Leather-bound NOTEBOOK' },
            { text: 'Red', value: 'Red NOTEBOOK' }],
            onFilter: (value, record) => record.NOTEBOOKtype.indexOf(value) === 0,
        },
        {
            title: 'Description',
            dataIndex: 'Subscriptiontype',
            key: 'x',
            render: (value, row) => value === '' ? 'None' : value.split(", ")[0],
            width: 150,
        },
        // {
        //     title: 'Uses Maturity',
        //     dataIndex: 'maturity',
        //     render: (value, row) => value == "0" ? 'No' : <a href={`pdf/htmls/${row.filename}_highlighted_maturity.html`}>Yes</a>,
        //     width: 150,
        // },
        // {
        //     title: 'Ref Pool',
        //     dataIndex: 'refpool',
        //     render: (value, row) => value == "0" ? 'No' : <a href={`pdf/htmls/${row.filename}_highlighted_refpool.html`}>Yes</a>,
        //     width: 150,
        // },
    ];


    return (
        <React.Fragment>



            <Row type="flex" justify="center" style={{ marginTop: 40 }}>
                <Title level={2} style={{ marginBottom: 40 }}>
                    Discourse</Title>
            </Row>

            <div style={{ marginTop: 80, minWidth: 800, margin: "auto" }}>
                <Row type="flex" justify="center" >

                    <Search
                        placeholder="search topics"
                        onSearch={value => console.log(value)}
                        style={{ width: 400 }}
                        enterButton
                    />

                </Row>

            </div>


            <div style={{ marginTop: 40 }}>


                <Row type="flex" justify="center">
                    <Table
                        rowKey={record => record.idx}
                        columns={columns}
                        dataSource={fileattr}
                        pagination={{ pageSize: 45 }}
                        scroll={{ y: 540 }}
                    // title={() => 'Sort and filter; click on any attribute to see evidence.'}
                    />
                </Row>

            </div>

            <div style={{ marginTop: 40 }}>



            </div>


        </React.Fragment>
    )
}


export default Landing;