import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

// import { EditorState } from 'draft-js';
// import Editor from 'draft-js-plugins-editor';
// import createMathjaxPlugin from 'draft-js-mathjax-plugin';


import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';

import './styles.css';


import MainEditor from './main-editor';



function Editing(props) {

    return (
        <React.Fragment>

            <MainEditor {...props} />

        </React.Fragment>

    )
}

const EditingBase = compose(
    withRouter,
    withFirebase,
)(Editing);

const EditingPage = (props) => {

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <EditingBase authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    // withQuiz,
)(EditingPage);



