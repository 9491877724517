import React from 'react';
import { Modal } from "antd";



export default function MathModal(props) {

    return (
        <Modal
            //   title="Input Math"
            style={{ top: 200 }}
            // bodyStyle={{opacity: 0.9}}
            mask={false}
            maskClosable={true}
            visible={props.visible}
            footer={null}
            closable={false}
            onCancel={props.handleModalCancel}
        />
    )
}