import { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import { message } from 'antd';

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export default function useCancellablePromise(cancelable = makeCancelable) {
    const emptyPromise = Promise.resolve(true);

    // test if the input argument is a cancelable promise generator
    if (cancelable(emptyPromise).cancel === undefined) {
        throw new Error('promise wrapper argument must provide a cancel() function')
    }
    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef([]);
    // const promises = useRef();

    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(
        () => {
            // initialization, if works... 
            promises.current = promises.current || [];
            // console.log("updated promises:", promises.current);

            // the cleanup
            return function cancel() {
                promises.current.forEach(p => p.cancel());
                promises.current = [];
            };
        }, []
    );


    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise

    function cancellablePromise(p) {
        const cPromise = cancelable(p);
        promises.current.push(cPromise);
        return cPromise.promise;
    }

    return { cancellablePromise };

    // return { promises };
}



export const useWrite2Db = (firebase, rootname, uid, childpath= '', method='push') => {


    // firebase, refname, uid, childname = ''

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    // const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);


    const ref = firebase.db.ref(`${rootname}/${uid}${childpath}`);


    useEffect(() => {


        const postData = async () => {

            if (Object.keys(data).length > 0) {
                setError(null);
                setLoading(true);

        switch (method) {
            // transaction isn't included as it inccurs multiple visits to server plus needs to use a function
          case 'set':
            ref.set(data)
            .catch(error => {
                setError(error);
                console.log('update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Edits saved");});
            break;
          case 'update':
            ref.update(data)
            .catch(error => {
                setError(error);
                console.log('update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Edits saved");});
            break;
          default:
            ref.push(data)
            .catch(error => {
                setError(error);
                console.log('update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Edits saved");});
        }
    

    
                setLoading(false);
            }
        };
        postData();
    }, [data]);

    return { loading, error, setData };
}



export function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  