import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
// import Goals from '../Goals';
// import Punchcard from '../Punchcard';
// import Routines from '../Routines';
// import ProfilePage, {LoginCredPage, ExtendMembershipPage, SettingsPage} from '../Account';
// import AdminPage from '../Admin';
import Editing from '../Editor';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';



const App = () => (
  <Router>
<React.Fragment>
      <Navigation/>
      <hr/>
      <div style={{minHeight: 800, margin: "auto", padding: 40}}>
      <Switch>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      {/* <Route path={ROUTES.GOALS} component={Goals} /> */}
      {/* <Route path={ROUTES.ROUTINES} component={Routines} /> */}
      {/* <Route path={ROUTES.PUNCHCARD} component={Punchcard} /> */}
      <Route path={ROUTES.EDIT} component={Editing} />
      {/* <Route path={ROUTES.LANDING+'?refer=:refer'} component={LandingPage} /> */}


      {/* <Route path={ROUTES.LOGINS} component={LoginCredPage} /> */}
   
      {/* <Route path={ROUTES.SETTINGS} component={SettingsPage} /> */}
      {/* <Route path="*" component={Notfound} /> */}
      <Redirect to="/" />
      </Switch>
      </div>
      <hr/>
      {/* <Footer transparent /> */}
      </React.Fragment>
  </Router>
);

export default withAuthentication(App);